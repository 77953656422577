// Here you can add other styles

@font-face {
    font-family: "FixelDisplayRegular";
    src: local("FixelDisplay-Regular"),
      url("../assets/fonts/FixelDisplay-Regular.woff2") format("woff2");
    font-display: swap;
  }


// html,
// body {
//   font-family: "FixelDisplayRegular" !important;
// }

.basic-select,
.react-datepicker-popper {
    z-index: 3 !important;
}

.related {
    position: relative;
    z-index: 3;
}

.order-input {
    margin-bottom: 10px;
    min-width: 162px !important;

    label {
        min-width: 140px;
    }
}

.edit-inner {
    display: flex;
    gap: 10px;
}

.object-img {
    display: block;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: 50px;
}

.user-img {
    display: block;
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    padding: 5px;
    border: 1px solid #ced4da;
    margin-top: 20px;
}

.MuiBox-root,
.card-body {
    img {
        max-width: 300px;
        max-height: 250px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.MuiBox-root {
    max-height: 100vh;
    overflow-y: auto;

    img  {
        margin-bottom: 1rem;
    }
}

.gallery-object {
    margin-bottom: 50px;
    &__inner {
        .carousel {
            .thumbs-wrapper {
                max-width: 500px;
                margin-left: 0;
            }
            .thumbs {
                padding-left: 0;
            }

            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &__item {
        a {
            color: #fff !important;
            margin-bottom: 10px;
        }
    }

    &__btn {
        display: flex;
        align-items: baseline;
        max-width: 500px;
        width: 100%;
        justify-content: space-between;
    }
}

.card-group-gallery {
    flex-wrap: wrap !important;
}

.carousel-inner,
.carousel-item {
    max-height: 500px;

    img {
        object-fit: cover;
        object-position: center;
        max-height: 500px;
    }
}

.info-list {
    .card-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.media-menu {

    img {
        max-width: 150px;
        max-height: 150px;
        background-color: rgb(223, 223, 223);
    }
}

.scanning-attr {
    button {
        display: block;
        margin-left: auto;
    }
}

.scaning-form {
    .select__menu {
        z-index: 100;
    }
}

.category-span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    color: hsl(0, 0%, 20%);
    padding: 5px;
    box-sizing: border-box;
    background-color: hsl(0, 0%, 90%);
    border-radius: 2px;
    margin: 0 5px 0 0;
}

.login-bg {
    border-color: transparent !important;
}

.home {
    &__action {
        margin: 50px 0;
        &-buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 25px;

            a {
                display: block;
                padding: 25px 50px;
                background-color: black;
                border-radius: 5px;
                color: #fff !important;
                text-decoration: none;
                transition: all 0.3s;
                font-size: 20px;
                font-weight: 600;

                @media screen and (max-width: 500px) { 
                    width: 100%;
                }

                &:hover {
                    background-color: rgba(0,0,0, 0.8)
                }
            }
        }
    }
    &__metricts{
        h5 {
            margin-bottom: 50px;
            @media screen and (max-width: 650px) { 
                margin-bottom: 10px;
            }
        }

        .css-upm00u-MuiResponsiveChart-container {
            @media screen and (max-width: 650px) { 
                width: 100%;
            }
        }
    }
}

.nav-group-toggle,
.nav-link {
    padding-left: 10px !important;
}

.sidebar-nav .nav-group .nav-group-items {
    padding-left: 15px;
}

.loader{
    display: inline-block;
    text-align: center;
    line-height: 86px;
    text-align: center;
    position: relative;
    padding: 0 48px;
    font-size: 28px;
    font-family: Arial, Helvetica, sans-serif;
    color: black;

        @media screen and (max-width: 650px) { 
            font-size: 18px;
        }
    }

    .loader:before, .loader:after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      background: currentColor;
      position: absolute;
      animation: load .7s infinite alternate ease-in-out;
      top: 0;
    }
    .loader:after {
      top: auto;
      bottom: 0;
    }
  @keyframes load {
    0% {
      left: 0;
      height: 43px;
      width: 15px;
      transform: translateX(0)
    }
    50% {
      height: 10px;
      width: 40px
    }
    100% {
      left: 100%;
      height: 43px;
      width: 15px;
      transform: translateX(-100%)
    }
  }

@media screen and (max-width: 1145px) { 
    .sum-inner {
        order: 4;
        min-width: 100%;
    }
    .card-body {
        table {
            td,tr,th {
                width: 100%;
                padding: 8px !important;
            }
            min-width: 270px;
        }
    }
}

@media screen and (max-width: 920px) { 
    .card-body {
        table {
            td,tr,th {
                padding: 8px !important;
            }
        }
    }
}

@media screen and (max-width: 800px) { 
    .css-1l9ohcm {
        width: 100%;
    }
 }