/* start - для OrdersForm.js */
.orders-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1600px;
    /* max-width: 1500; */
    /* background-color: background.paper; */
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    padding: 15px;
}

.orders-modal__header {
    display: flex;
}

.orders-modal__header2 {
    display: flex;
}

.orders-modal__title2 {
    font-size: 1.25rem;
}
  
@media (max-width: 1600px) {
    .orders-modal {
        width: 95vw;
    }
}

@media (max-width: 1200px) {
    .orders-modal__header {
        display: block;
    }
}

@media (max-width: 992px) {
    .orders-modal {
        height: 98vh;
        overflow-x: auto;
    }
}

@media (max-width: 768px) {
    .orders-modal__header2 {
        display: block;
    }   
    .orders-modal__title2 {
        font-size: 1rem;
        margin-left: 0px !important;
    }
}

/* @media (max-width: 576px) { */
@media (max-width: 480px) {
    .orders-modal {
        padding-top: 50px;
    }
}
   
/* @media (max-width: 1200px) {
    .orders-modal {
        width: 970px
        width: 95vw;
        padding: calc(20px + (50 - 20) * ((100vw - 320px) / (1200 - 320)));
    }
} */

.orders__linkToOrderProduct {
    text-decoration: underline;
    color: rgb(3, 152, 158);
    cursor: pointer;
}

.orders__linkToOrderProductIcon {
    color: rgb(3, 152, 158);
    cursor: pointer;
}
  
.orders-choose__table {
    width: 100%;
}

.orders-choose__th {
    font-size: 14px;
}

.orders-choose__th-img {
    width: 50px;
}

.orders-choose__th-price {
    width: 50px;
}

.orders-choose__th-count {
    width: 90px;
}
.orders-choose__TC {
    display: flex;
    flex-direction: column;
}

.orders-choose__th-action {
    display: block;
    width: 35px;
}

.orders-choose__mobile, .orders-choose__short-mobile {
    display: none;
}
/* .orders-choose__PC, .orders-choose__short-PC {
    display: block;
} */

@media (max-width: 1400px) {
    .orders-choose__th-img {
        width: 40px;
    }
}

@media (max-width: 1200px) {
    .orders-choose__mobile{
        display: block;
    }
    .orders-choose__PC
    /* , .orders-choose__short-PC  */
    {
        display: none;
    }
    .orders-choose__th-img {
        width: 30px;
    }
    .orders-choose__th-action {
        display: none;
    }
}

@media (max-width: 991px) {
    .orders-choose__th-action {
        display: block;
        width: 35px;
    }
}

@media (max-width: 576px) {
    .orders-choose__short-PC {
        display: none;
    }
    .orders-choose__short-mobile {
        display: block;
    }
    .orders-choose__th-img {
        display: none;
    }
    .orders-choose__th-action {
        display: none;
    }
    .orders-choose__cart {
        display: none;
        width: 35px !important;
    }
}

.orders-choose__img {
    width: 50px;
}

.orders-choose__td-name {
    max-width: 150px;
    overflow: hidden;
    /* font-size: 14px; */
    font-size: 24px;
}

.orders-choose__name {
    cursor: pointer;
    text-decoration: underLine;
    color: rgb(3, 152, 158);
}
  
.orders-choose__text {
    /* font-size: 14px; */
    font-size: 17px;
    min-width: 100px;
}

.orders-choose__count {
    padding-left: 5px !important;
    padding-right: 5px !important;
    /* width: 90px; */
    width: 120px;
    line-height: 17px; 
}

.orders-choose__count-balance_container {
    border: 1px solid #ccc;
    background: #f1eeee;
    padding-bottom: 10px;
    border-radius: 10px;
    position: absolute;
    /* top: 10px; */
    top: 45px;
    right: 5px;
}

.orders-choose__count-balance_title-container {
    border-bottom: 1px solid #ccc;
    position: relative;
    min-height: 40px;
    max-height: 65px;
    background-color: #d6d7d8f2;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    padding: 10px;
}


.orders-choose__count-balance_title {
    font-weight: 600;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.orders-choose__count-balance_title-productName {
    max-width: 90%;
    font-size: 12px;
    margin-top: 5px;
    font-weight: 400;
}

.orders-choose__count-balance_storages {
    position: relative;
    padding: 10px;
    /* margin-bottom: 5px; */
    min-height: 100px;
    max-height: 222px;
    overflow: auto;
}

.orders-choose__count-balance_storages ul {
    list-style: none;
    padding-left: 10px;
}

.orders-choose__count-balance_storages ul li {
    font-size: 12px;
    margin-bottom: 4px;
}

.orders-choose__count-balance__btn-show {
    color: rgb(3, 152, 158) !important;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer; 
}

.orders-choose__count-balance__btn-close {
    width: 25px;
    height: 25px;
    background-color: #6c757d;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 5px;
    font-weight: 500;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.orders-choose__count-balance__btn-close:hover {
    background-color: red;
    color: #000;
    border-color: #000;
}
.orders-choose__count-balance__btn-close:active {
    transform: scale(0.9);
}

.orders-choose__count-balance__btn-show:hover {
    cursor: pointer;
}

.orders-choose__count-1r {
    /* width: 90px; */
    width: 120px;
    margin-bottom: 5px;
}
.orders-choose__count-2r {
    /* width: 90px; */
    width: 120px;
    display: flex;
    justify-content: space-between;
}

.orders-choose__count-input {
    /* width: 90px; */
    width: 120px;
    font-size: 14px;
}

.orders-choose__count-button {
    /* width: 40px; */
    width: 50px;
    font-size: 14px;
}

.orders-choose__cart-mobile {
    display: none;
}

@media (max-width: 1400px) {
    .orders-choose__img {
        width: 40px;
    }
}

@media (max-width: 1200px) {
    .orders-choose__count-1r {
        width: 90px;
    }
    .orders-choose__count-2r {
        width: 90px;
    }
    .orders-choose__count-input {
        width: 90px;
    }
    .orders-choose__count-button {
        width: 40px;
    }
    
    /* .orders-choose__cart {
        display: none;
    } */
    .orders-choose__img {
        width: 30px;
    }
    .orders-choose__text {
        min-width: 80px;
    }
}

@media screen and (max-width: 991px) {
    .orders-choose__td-name {
        font-size: 16px;
    }
    .orders-choose__text {
        min-width: 115px;
        font-size: 16px;
    }

    .orders-choose__count-1r {
        width: 120px;
    }
    .orders-choose__count-2r {
        width: 120px;
    }
    .orders-choose__count-input {
        width: 120px;
    }
    .orders-choose__count-button {
        width: 50px;
    }

    .orders-choose__th-action {
        display: block;
    }
    .orders-choose__cart {
        display: block;
    }

    .orders-choose__count-balance_container {
        top: auto;
        bottom: 5px;
        right: 5px;
    }
}

@media (max-width: 576px) {
    .orders-choose__cart {
        display: none;
    }
    .orders-choose__td-img {
        display: none;
    }
    .orders-choose__th-action, .orders-choose__cart {
        display: none;
        padding: 3px !important;
    }
    .orders-choose__td-name, .orders-choose__text {
        font-size: 14px;
        min-width: 60px;
    }

    .orders-choose__count-1r {
        width: 90px;
    }
    .orders-choose__count-2r {
        width: 90px;
    }
    .orders-choose__count-input {
        width: 90px;
    }
    .orders-choose__count-button {
        width: 40px;
    }

    .orders-choose__cart-mobile {
        display: block;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        /* width: 120px; */
    }
}

@media screen and (max-width: 448px) {  
    .orders-choose__td-name, .orders-choose__text {
        font-size: 13px;
    }

    .orders-choose__count-1r, .orders-choose__count-2r {
        width: 100%;
    }

    .orders-choose__count-input {
        width: 100%;
    }
    
    .orders-choose__count-button {
        width: 100%;
    }
  }


/* Start - для ViewOrdersMain.js */
.orders-ViewMain__TC {
    display: flex;
    flex-direction: column;
}
/* End - для ViewOrdersMoin.js */


/* Start - для ViewOrdersModal.js */
.orders-ViewModal__mobile {
    display: none;
}
.orders-ViewModal__PC {
    display: block;
}
.orders-ViewModal__TC {
    display: flex;
    flex-direction: column;
}

/* .orders-ViewModal__container-PC */
.orders-ViewModal__container-mobile {
    /* display: block; */
    display: none;
}

@media (max-width: 1200px) {
    .orders-ViewModal__mobile {
        display: block;
    }
    .orders-ViewModal__PC {
        display: none !important;
    }
}

/* End - для ViewOrdersModal.js */


/* Start - для ProductInfo.js */
.orders-productInfo__text {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5px;
}

.orders-productInfo__count {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
}

.orders-productInfo__count-btn {
    width: 45px;
}

.orders-productInfo__count-input {
    width: 100%;
}

.orders-productInfo__btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .orders-productInfo__price span {
    margin-bottom: 5px;
} */

.orders-productInfo__price-input {
    margin-top: 5px;
    width: 100%;
}

@media (max-width: 1200px) {
    .orders-productInfo__count, .orders-productInfo__btn-container, .orders-productInfo__price {
        width: 217px;
    }
}

/* End - для ProductInfo.js */

.orders-update_modal-updateProductItem {
    width: 900px;
}

@media (max-width: 991px) {
    .orders-update_modal-updateProductItem {
        max-width: 500px;
    }
} 
@media (max-width: 576px) {
    .orders-update_modal-updateProductItem {
        width: 90%;
    }
} 


/* Start - для UpdateProductItem.js */
.orders-update__text {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5px;
}
.orders-update__input-price {
    width: 100%;
    font-size: 17px;
    text-align: center;
}

.orders-update__btn-counter {
    height: 28px;
    width: 28px;
    font-size: 17px;
}

.orders-update__input-counter {
    width: calc(100% - 30px);
    font-size: 17px;
}

/* End - для UpdateProductItem.js */


.orders-tableHeader {
    padding-left: 12px;
    font-size: 20px;
    color: rgb(33, 37, 41);
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 32px;
}

.orders-CAccordion {
    font-size: 14px;
}

.orders-filterSize {
    font-size: 13px !important;
}

@media (max-width: 991px) {
    .orders-tableHeader {
        line-height: 25px;
    }
}

@media (max-width: 576px) {
    .orders-tableHeader {
        padding-left: 0px;
    }

    .orders-ViewModal__container-PC {
        /* display: block; */
        display: none;
    }
    .orders-ViewModal__container-mobile {
        display: block;
        /* display: none; */
    }
}
  
/* start orders page QuickProductAddition */
.orders-btn__close {
    width: 106px;
    line-height: 17px !important;
    padding: 0px 2px 5px 2px !important;
    cursor: pointer !important;
    position: absolute;
    /* top: -40px; */
    top: -5px;
    right: -10px;
    border: 1px solid #000;
    text-align: center;
}
.orders-btn__close:hover {
    background-color: red !important;
    color: #000 !important;
}
.orders-btn__close:active {
    transform: scale(0.9);
}

.orders-quickProductAddition__btn-close {
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    background-color: #6c757d;
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
    position: absolute;
    top: -65px;
    right: -25px;
    cursor: pointer;
}
.orders-quickProductAddition__btn-close:hover {
    background-color: red;
    color: #000;
    border: 1px solid #000;
}
.orders-quickProductAddition__btn-close:active {
    transform: scale(0.9);
}

@media (max-width: 1200px) {
    .orders-btn__close {
        /* top: -70px; */
        top: -5px;
    }
}

@media (max-width: 768px) {
    .orders-btn__close {
        /* top: -90px; */
        top: -5px;
    }
}

/* @media (max-width: 576px) { */
@media (max-width: 480px) {
    .orders-btn__close {
        /* top: -75px; */
        /* top: -130px; */
        top: -40px;
    }
}

@media (max-width: 403px) {
    .orders-quickProductAddition__btn-close {
        top: -95px;
        right: -25px;
    }
}

@media (max-width: 350px) {
    .orders-btn__close {
        top: -140px;
    }
}
  
/* end orders page QuickProductAddition */
  
/* start orders page AddProductToOrder */
.orders-chooseProduct__container {
    margin-top: 5px;
    padding: 0px 5px 5px;
    border: 5px solid grey;
}
  
@media (max-width: 991px) {
    .orders-chooseProduct__container {
        border: none;
        border-top: 7px solid grey;
        margin-top: 30px;
    }
}
  
.orders-viewProductOrders__container {
    margin-top: 5px;
    padding: 0px 5px 5px;
    border: 5px solid grey;
}
  
@media (max-width: 991px) {
    .orders-viewProductOrders__container {
        border: none;
        border-top: 7px solid grey;
        margin-top: 15px;
    }
}
@media (max-width: 850px) {
    .order-updateProductItem {
        width: 90%;
    }
}
  
.orders-productInfo__container, .orders-updateProductItem__container {
    margin-top: 5px;
    border: 5px solid grey;
}
  
.orders-productInfo__btn-close, .orders-updateProductItems__btn-close {
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    background-color: #6c757d;
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
.orders-productInfo__btn-close:hover, .orders-updateProductItems__btn-close:hover {
    background-color: red;
    color: #000;
    border: 1px solid #000;
}
.orders-productInfo__btn-close:active, .orders-updateProductItems__btn-close:active {
    transform: scale(0.9);
}

.orders-updateProductItems__photo {
    position: relative;
    height: 200px;
    width: 200px;
}

.orders-updateProductItems__photo img {
    width: 100%;
}
  
.orders-updateProductItems__photo:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    background-color: rgba(0,0,0,0.5);
}

@media (max-width: 991px) {
    .orders-productInfo__container, .orders-updateProductItem__container {
        border: none;
        border-top: 7px solid grey;
        margin-top: 15px;
    }
}

/* end orders page AddProductToOrder */